<template>
  <div id="login">
    <div class="login-content">
      <a-row justify="center" type="flex" align="middle">
        <a-col
          :md="{ span: 24 }"
          :lg="{ span: 12 }"
          style="text-align: center; padding-bottom: 20px"
        >
          <!-- <img src="@/assets/Logo-Hasan.png" width="300" /> -->
          <img draggable="false" :src="logo" width="300" />
        </a-col>
        <!-- <a-col :md="{ span: 24 }" :lg="{ span: 12 }">
          <div class="card">
            <div class="card-body">
              <h1 id="title-login">Welcome to Digital Classroom</h1>
              <InputLogin />
            </div>
          </div>
        </a-col>-->
        <a-col
          id="box-login"
          :md="{ span: 24 }"
          :lg="{ span: 24 }"
          style="text-align: center"
        >
          <h1 id="title-login">{{ header }}</h1>
          <slot></slot>
        </a-col>
      </a-row>
      <a-row
        justify="center"
        style="text-align: center; margin-top: 40px"
        type="flex"
        align="bottom"
      >
        <a-col :span="24">
          <p style="font-size: 16px">Copyright &copy; 2020 Hasan Edutech</p>
        </a-col>
      </a-row>
    </div>
  </div>
</template>
<script>
// import InputLogin from '@/components/app/InputLogin'
import config from '@/config'
export default {
  name: 'Login',
  created() {
    this.logo = config.school.logo
  },
  data() {
    return {
      logo: '',
    }
  },
  props: ['header'],
}
</script>
<style lang="scss">
#login {
  min-height: 100vh;
  padding: 0 38px;
  background: linear-gradient(
      rgba(240, 242, 244, 0.9),
      rgba(240, 242, 244, 0.9)
    ),
    url("../../../assets/background.svg");
  background-size: contain;
  overflow: hidden !important;
  // position: relative;

  .login-content {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    /* margin: auto 0; */
    justify-content: center;
    // position: absolute;
    // width: 100%;
    #box-login {
      width: 555px;
      // background: var(--unnamed-color-ffffff) 0% 0% no-repeat padding-box;
      background: #ffffff 0% 0% no-repeat padding-box;
      box-shadow: 0px 0px 10px #0419301a;
      border-radius: 6px;
      opacity: 1;
      padding: 80px 40px;
      text-align: left !important;
      @media (max-width: 575px) {
        width: 400px;
      }
    }

    #title-login {
      text-align: left;
      width: 320px;
      height: 81px;
      margin-bottom: 41px;
    }
  }
}
</style>
